@font-face {
  font-family: 'SFProDisplay';
  src: url('./SFProDisplay-Light.woff2') format('woff2'),
  url('./SFProDisplay-Light.woff') format('woff'),
  url('./SFProDisplay-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('./SFProDisplay-Bold.woff2') format('woff2'),
  url('./SFProDisplay-Bold.woff') format('woff'),
  url('./SFProDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('./SFProDisplay-Medium.woff2') format('woff2'),
  url('./SFProDisplay-Medium.woff') format('woff'),
  url('./SFProDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('./SFProDisplay-Regular.woff2') format('woff2'),
  url('./SFProDisplay-Regular.woff') format('woff'),
  url('./SFProDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('./SFProDisplay-Semibold.woff2') format('woff2'),
  url('./SFProDisplay-Semibold.woff') format('woff'),
  url('./SFProDisplay-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lora';
  src: url('./Lora-Regular.woff2') format('woff2'),
  url('./Lora-Regular.woff') format('woff'),
  url('./Lora-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lora';
  src: url('./Lora-Medium.woff2') format('woff2'),
  url('./Lora-Medium.woff') format('woff'),
  url('./Lora-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lora';
  src: url('./Lora-Bold.woff2') format('woff2'),
  url('./Lora-Bold.woff') format('woff'),
  url('./Lora-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lora';
  src: url('./Lora-SemiBold.woff2') format('woff2'),
  url('./Lora-SemiBold.woff') format('woff'),
  url('./Lora-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
